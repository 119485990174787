import React from "react"
import PropTypes from "prop-types"


class FormAudit extends React.Component {
  render() {
    return (
      <section className="overflow-hidden" id="audit">
        <div className="container py-12 md:py-16 xl:py-20" id="lead-generation">
          <div className="flex flex-wrap xl:items-center justify-center">
            <div className="w-full text-center">
              <h2>Get a Free Instant Website Audit</h2>
              <div className="w-10/12 md:w-7/12 mx-auto mb-8">
                <p>Enter your URL address to receive an <strong>instant free analysis of your website</strong>. We will grade your company website on the key parameters, from SEO to usability, and show you <strong>areas for improvement</strong>.</p>
              </div>
            </div>
            <div className="w-full lg:w-1/2 xl:w-4/12">
              <form className="so-widget-form" id="so1602062575" action="https://www.websiteauditserver.com/process-embedded.inc" target="so-iframe" data-behaviour="modal" data-title="Website Report for " data-touch="Thank you for requesting a report. We'll be in touch shortly!" onsubmit="return soSubmit(this)">
                <input id="so-typeso1602062575" type="hidden" name="type" value="pdf"/>
                <input type="hidden" name="uid" value="13804"/>
                <input type="hidden" name="behaviour" value="modal"/>
                <input type="hidden" name="template" value="885"/>


                <div id="so-fieldswrapso1602062575">
                  <div className="flex flex-wrap md:-mx-4">
                    <div className="w-full md:w-1/2 md:px-4">
                      <label htmlFor="first_name" className="block mb-3">
                        <span className="text-secondary-dark font-display pt-2 pb-1 block">Your First Name:</span>
                        <input type="text" name="first_name" id="so-first-nameso1602062575" className="block w-full p-4 border border-grey-medium outline-none focus:border-primary appearance-none mb-4" placeholder="First Name" data-validation="Please enter your First Name" />
                      </label>
                    </div>
                    
                    <div className="w-full md:w-1/2 md:px-4">
                      <label htmlFor="last_name" className="block mb-3">
                        <span className="text-secondary-dark font-display pt-2 pb-1 block">Your Last Name:</span>
                        <input type="text" name="last_name" id="so-last-nameso1602062575" className="block w-full p-4 border border-grey-medium outline-none focus:border-primary appearance-none mb-4" placeholder="Last Name" data-validation="Please enter your Last Name" />
                      </label>
                    </div>
                  </div>

                  <label htmlFor="first_name" className="block mb-3">
                    <span className="text-secondary-dark font-display pt-2 pb-1 block">Your Website URL:</span>
                    <input type="text" name="domain" id="so-domainso1602062575" className="block w-full p-4 border border-grey-medium outline-none focus:border-primary appearance-none mb-4" placeholder="Website URL" data-validation="Please enter a correct website domain" />
                  </label>
                  
                  <label htmlFor="email" className="block mb-3">
                    <span className="text-secondary-dark font-display pt-2 pb-1 block">Your Email Address:</span>
                    <input type="text" name="email" id="so-emailso1602062575" className="block w-full p-4 border border-grey-medium outline-none focus:border-primary appearance-none mb-4" placeholder="Email Address" data-validation="Please enter your email" />
                  </label>
                  
                  <label htmlFor="phone" className="block mb-6">
                    <span className="text-secondary-dark font-display pt-2 pb-1 block">Your Phone Number:</span>
                    <input type="text" name="phone" id="so-phoneso1602062575" className="block w-full p-4 border border-grey-medium outline-none focus:border-primary appearance-none mb-4" placeholder="Phone Number" data-validation="Please enter your phone" />
                  </label>
                  
                  <input type="submit" className="btn block mx-auto" id="so-submitso1602062575" value="Download Audit"></input>

                </div>
              </form>

              <small className="block mt-6 text-center">By submitting your data you are agreeing to our <a target="_blank" rel="noopener noreferrer" href="http://yourdata.adtrak.co.uk/" className="underline">Privacy Policy</a></small>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

FormAudit.propTypes = {
  heading: PropTypes.string.isRequired,
}

FormAudit.defaultProps = {
  heading: `Want to know more?`,
}

export default FormAudit
