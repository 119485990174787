import React from "react"
import PropTypes from "prop-types"
import Triangle from "../triangle/triangle"
import SimpleReactValidator from "simple-react-validator"
import { navigateTo } from "gatsby"
import ReCAPTCHA from "react-google-recaptcha";

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class FormRankingReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first: "",
      last: "",
      email: "",
      phone: "",
      company: "",
      website: "",
      enquiry: "",
      submitting: false
    }
  }

  componentWillMount() {
    this.validator = new SimpleReactValidator();
  }

  handleSubmit = e => {
    if (this.validator.allValid()) {
      const form = e.target;
      this.setState({
        submitting: true
      })
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state
        })
      })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch(error => alert(error));
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
    e.preventDefault();
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleRecaptcha = value => {
    this.setState({ "g-recaptcha-response": value });
  };
  

  render() {
    const { first, last, email, phone, company, website, enquiry } = this.state;
    return (
      <section className="mb-16 overflow-hidden lg:mb-32" id="form">
        <div className="container text-center">
          { !this.props.contactForm ? (
          <>
            <div id="enquiryform" className="w-full mx-auto mb-8 text-center md:w-2/3 lg:w-5/12 md:mb-12">
              <span className="block h2">{this.props.heading}</span>
              <p className="text-xl md:text-xl">Enter your URL address to receive your ranking report.</p>
            </div>

            <ul className="justify-center mb-8 md:flex md:flex-wrap md:mb-16">
              <li className="mb-1 text-xl text-current font-display md:mb-3 md:px-6 lg:px-10 w-full"><span className={`mr-2 inline-block text-primary`}><Triangle size={`small`} /></span> See where your site ranks on Google</li>
              <li className="mb-1 text-xl text-current font-display md:mb-3 md:px-6 lg:px-10 w-full"><span className={`mr-2 inline-block text-primary`}><Triangle size={`small`} /></span> We provide both desktop and mobile rankings</li>
              <li className="mb-1 text-xl text-current font-display md:mb-3 md:px-6 lg:px-10 w-full"><span className={`mr-2 inline-block text-primary`}><Triangle size={`small`} /></span> Fill in the form below</li>
            </ul>
          </>
          ) : (
            <></>
          )}
            
          <form
            name="Free Ranking Report Form"
            onSubmit={this.handleSubmit}
            className="block w-full max-w-lg mx-auto text-left callbackform"
            method="post"
            action="/thank-you"
            data-netlify-recaptcha="true"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" className="hidden" name="form-name" value="callback-form" />
            <p hidden className="hidden"><label>Don’t fill this out:{" "}<input name="bot-field" /></label></p>

            <div className="flex flex-wrap md:-mx-3">
              <div className="w-full md:w-1/2 md:px-3">
                <label htmlFor="first" className="block mb-2">
                  <span className={this.props.lightLabels ? 'text-white font-display pt-2 pb-1 block' : 'text-secondary-dark font-display pt-2 pb-1 block'}>First Name: *</span>
                  <input type="text" name="first" id="first" className="block w-full p-4 text-black border outline-none appearance-none border-grey-medium focus:border-primary" placeholder="First Name" value={first} onChange={this.handleChange} />

                  <span className="block mt-2 text-sm text-primary">
                    {this.validator.message('first', first, 'required')}
                  </span>
                </label>
              </div>
              
              <div className="w-full md:w-1/2 md:px-3">
                <label htmlFor="lastname" className="block mb-2">
                  <span className={this.props.lightLabels ? 'text-white font-display pt-2 pb-1 block' : 'text-secondary-dark font-display pt-2 pb-1 block'}>Last Name: *</span>
                  <input type="text" name="last" id="last" className="block w-full p-4 text-black border outline-none appearance-none border-grey-medium focus:border-primary" placeholder="Last Name" value={last} onChange={this.handleChange} />

                  <span className="block mt-2 text-sm text-primary">
                    {this.validator.message('last', last, 'required')}
                  </span>
                </label>
              </div>
            </div>

            <label htmlFor="website" className="block mb-2">
              <span className={this.props.lightLabels ? 'text-white font-display pt-2 pb-1 block' : 'text-secondary-dark font-display pt-2 pb-1 block'}>Website URL: *</span>

              <input type="text" name="website" id="website" className="block w-full p-4 text-black border outline-none appearance-none border-grey-medium focus:border-primary" placeholder="https://example.co.uk" value={website} onChange={this.handleChange} />
                
              <span className="block mt-2 text-sm text-primary">
                {this.validator.message('website', website, 'required')}
              </span>
            </label>

            <label htmlFor="company" className="block mb-2">
              <span className={this.props.lightLabels ? 'text-white font-display pt-2 pb-1 block' : 'text-secondary-dark font-display pt-2 pb-1 block'}>Company: *</span>

              <input type="text" name="company" id="company" className="block w-full p-4 text-black border outline-none appearance-none border-grey-medium focus:border-primary" placeholder="Company" value={company} onChange={this.handleChange} />
                
              <span className="block mt-2 text-sm text-primary">
                {this.validator.message('company', company, 'required')}
              </span>
            </label>

            <label htmlFor="phoneNumber" className="block mb-2">
              <span className={this.props.lightLabels ? 'text-white font-display pt-2 pb-1 block' : 'text-secondary-dark font-display pt-2 pb-1 block'}>Phone Number: *</span>
              <input type="text" name="phone" id="phone" className="block w-full p-4 text-black border outline-none appearance-none border-grey-medium focus:border-primary" placeholder="Phone Number" value={phone} onChange={this.handleChange} />

              <span className="block mt-2 text-sm text-primary">
                {this.validator.message('phone', phone, 'required|phone')}
              </span>
            </label>

            <label htmlFor="email" className="block mb-2">
              <span className={this.props.lightLabels ? 'text-white font-display pt-2 pb-1 block' : 'text-secondary-dark font-display pt-2 pb-1 block'}>Email: *</span>
              <input type="email" name="email" id="email" className="block w-full p-4 text-black border outline-none appearance-none border-grey-medium focus:border-primary" placeholder="Email" value={email} onChange={this.handleChange} />
                
              <span className="block mt-2 text-sm text-primary">
                {this.validator.message('email', email, 'required|email')}
              </span>
            </label>
            
            <div className="flex justify-center pb-4">
              <ReCAPTCHA
                ref="recaptcha"
                sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY}
                onChange={this.handleRecaptcha}
              />
            </div>

            <div className="flex justify-center">
              <button type="submit" disabled={this.state.submitting} className="btn btn--primary">{ this.state.submitting ? 'Sending...' : 'Get Free Report' }</button>
            </div>
          </form>
        </div>
      </section>
    )
  }
}

FormRankingReport.propTypes = {
  heading: PropTypes.string.isRequired,
}

FormRankingReport.defaultProps = {
  heading: `Get a Free Ranking Report`,
}

export default FormRankingReport
