import React from "react"

const AldVideo = () => (

<div style={{ position: 'relative', height: 0, paddingBottom: '56.25%' }}>
    <iframe
        className='sproutvideo-player'
        src='https://videos.sproutvideo.com/embed/d390dbb4141ae0c45a/39d5cd3295d421c8?playerTheme=dark&amp;playerColor=041448'
        style={{ position: 'absolute', width: '100%', height: '100%', left: 0, top: 0 }}
        frameborder='0'
        allowfullscreen
        referrerpolicy='no-referrer-when-downgrade'
        title='Video Player'
    ></iframe>

</div>

)

export default AldVideo