import React from "react"
import SEO from "../components/seo"
import UspBand from "../components/uspBand/uspBand"
import AldVideo from "../components/aldVideo/aldVideo"
import FormCta from "../components/formCta/formCta"
import Shape from "../components/shape/shape"
import FormAudit from "../components/formAudit/formAudit"
import FormRankingReport from "../components/formRankingReport/formRankingReport"
import QuoteBuilder from "../components/form/quoteBuilder"
import FeaturedCaseStudy from "../components/featuredCaseStudy/featuredCaseStudy"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Triangle from "../components/triangle/triangle"
import Reviews from "../components/reviews/reviews"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
const slugify = require("slugify")


const DigitalServicePage = ({ data: { service, shared, page }, location }) => {

  return (
    <>
      <SEO
        titleOverride={service.metaTags && service.metaTags.title ? service.metaTags.title : service.title}
        descriptionOverride={service.metaTags && service.metaTags.description ? service.metaTags.description : null}
        pathnameOverride={location.pathname}
        imageOverride={service.metaTags && service.metaTags.image ? service.metaTags.image.url : null}
        noIndex={service.ppcOnlyPage ? true : false}
      />
      
      <section className="mb-12">
        <div className="container">
          <div className="w-full mx-auto md:w-10/12">
            <div className="w-full mx-auto text-center md:w-9/12 lg:w-7/12">
            <ol className="block mb-1">
                <li className="text-base text-grey-dark">
                  <Link to="/services/" className="text-base underline transition duration-500 ease-in-out text-grey-dark hover:text-secondary-dark focus:text-secondary-dark">
                    <span>Digital</span>
                  </Link> /
                </li>
              </ol>
              
              <span className="block h1">{ service.heroHeading }</span>
              <div className="content content--reset" dangerouslySetInnerHTML={{ __html: service.heroExcerpt }}></div>
            </div>
          </div>
        </div>
      </section>
      { service.showQuoteBuilderForm ? ( 
        <>
          { service.showAuditForm ? (
            <section className="mb-16 lg:mb-24">
              <p className="px-12 mb-8 text-xl leading-tight text-center align-middle lg:text-2xl font-display md:px-24">Get a FREE Instant <span className="text-primary">Website Audit</span> <span className="block mt-3 ml-2 align-middle sm:mt-0 sm:inline-block"><a href="#audit" className="text-base capitalize btn btn--outline">Find Out More</a></span></p>
            </section>
          ) : (
            <section className="mb-16 lg:mb-24">
              <p className="px-12 mb-8 text-xl leading-tight text-center align-middle lg:text-2xl font-display md:px-24">Get in touch today on <a href="tel:01159598900" className="inline-block break-all transition duration-500 ease-in-out text-primary hover:text-primary-dark focus:text-primary-dark contactlink">0115 959 8900</a> or <span className="block mt-3 ml-2 align-middle sm:mt-0 sm:inline-block"><a href="#quote" className="text-base capitalize btn btn--outline">Get a quote online</a></span></p>
            </section>
          )}
        </>
      ) : <>
      { service.showAuditForm ? (
        <section className="mb-16 lg:mb-24">
          <p className="px-12 mb-8 text-xl leading-tight text-center align-middle lg:text-2xl font-display md:px-24">Get a FREE Instant <span className="text-primary">Website Audit</span> <span className="block mt-3 ml-2 align-middle sm:mt-0 sm:inline-block"><a href="#audit" className="text-base capitalize btn btn--outline">Find Out More</a></span></p>
        </section>
      ) : (
        <section className="mb-16 lg:mb-24">
          <p className="px-12 mb-8 text-xl leading-tight text-center align-middle lg:text-2xl font-display md:px-24">Get in touch today on <a href="tel:01159598900" className="inline-block break-all transition duration-500 ease-in-out text-primary hover:text-primary-dark focus:text-primary-dark contactlink">0115 959 8900</a> or <span className="block mt-3 ml-2 align-middle sm:mt-0 sm:inline-block"><a href="#form" className="text-base capitalize btn btn--outline">Get in touch</a></span></p>
        </section>
      )}
    </>}

    <div className="container flex flex-wrap items-center justify-center py-4 mb-6 md:md:items-stretch lg:mb-24">
      <div className="flex flex-wrap justify-center md:w-full md:justify-between md:-mx-2">
        <div class="w-1/3 lg:w-1/5 p-1 md:p-4 flex">
          <div className="flex flex-wrap items-center justify-start w-full p-0 border-2 outline-none md:p-6 border-grey">
            <div className="w-full m-auto md:px-2">
              <div className="m-auto text-center gatsby-image-wrapper ">
                <Img fluid={shared.agencyAwardLogo.fluid} className="w-full" />
              </div>
            </div>
          </div>
        </div>
        <div class="w-1/3 lg:w-1/5 p-1 md:p-4 flex">
          <div className="flex flex-wrap items-center justify-start w-full p-0 border-2 outline-none md:p-6 border-grey">
            <div className="w-full m-auto md:px-2">
              <div className="m-auto text-center gatsby-image-wrapper">
                <Img fluid={shared.awardLogo.fluid} className="w-full" />
              </div>
            </div>
          </div>
        </div>

        {/* Swap out PPC and web award */}

        {service.slug === "ppc" && (
          <div className="w-1/3 lg:w-1/5 p-1 md:p-4 flex">
            <div className="flex flex-wrap items-center justify-start w-full p-0 border-2 outline-none md:p-6 border-grey">
              <div className="w-full m-auto md:px-2">
                <div className="m-auto text-center gatsby-image-wrapper">
                  <Img fluid={shared.googlePartner.fluid} className="w-full" />
                </div>
              </div>
            </div>
          </div>
        )}

        {!service.treeParent && service.slug !== "ppc" &&  (
          <div className="w-1/3 lg:w-1/5 p-1 md:p-4 flex">
            <div className="flex flex-wrap items-center justify-start w-full p-0 border-2 outline-none md:p-6 border-grey">
              <div className="w-full m-auto md:px-2">
                <div className="m-auto text-center gatsby-image-wrapper">
                  <Img fluid={shared.webExcellenceAwardLogo.fluid} className="w-full" />
                </div>
              </div>
            </div>
          </div>
        )}

        {service.treeParent && service.treeParent.slug !== null && service.treeParent.slug !== "" && (
          service.treeParent.slug === "ppc" ? (
            <div className="w-1/3 lg:w-1/5 p-1 md:p-4 flex">
              <div className="flex flex-wrap items-center justify-start w-full p-0 border-2 outline-none md:p-6 border-grey">
                <div className="w-full m-auto md:px-2">
                  <div className="m-auto text-center gatsby-image-wrapper">
                    <Img fluid={shared.googlePartner.fluid} className="w-full" />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-1/3 lg:w-1/5 p-1 md:p-4 flex">
              <div className="flex flex-wrap items-center justify-start w-full p-0 border-2 outline-none md:p-6 border-grey">
                <div className="w-full m-auto md:px-2">
                  <div className="m-auto text-center gatsby-image-wrapper">
                    <Img fluid={shared.webExcellenceAwardLogo.fluid} className="w-full" />
                  </div>
                </div>
              </div>
            </div>
          )
        )}

        {/* END OF Swap out PPC and web award */}
        
        <div class="w-full lg:w-2/5 p-4 flex">
          <div className="flex flex-wrap items-center justify-center w-full p-6 border-2 outline-none border-grey">
            <div className="flex justify-center w-full md:flex-1 md:px-2">
              <span className="block pb-2 mb-2 text-base text-center lg:pb-0 md:text-lg md:mb-0">Trusted by our many happy customers</span>
            </div>
            <div className="w-full md:w-auto md:px-2">
              <div className="w-56 mx-auto text-center">
                <Reviews hideExtraText />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    { service.reportingBackgroundImage ? (
      <div className="relative mb-16 overflow-hidden bg-secondary">
        <div className="absolute top-0 right-0 z-10 hidden lg:-mr-48 xl:mr-0 reporting-img lg:block">
          <Img fluid={service.reportingBackgroundImage.fixed} className="w-auto" /> 
        </div>  
        <div className="container relative flex flex-wrap items-center">    
          <div className="w-full py-20 text-white h3 lg:w-1/2 reporting-text" dangerouslySetInnerHTML={{ __html: service.reportingText }}></div>
        </div>
        { service.reportingBackgroundImageMobile ? (
        <div className="bottom-0 right-0 z-10 block w-full md:-mb-36 lg:hidden lg:-mr-48 xl:mr-0 reporting-img">
          <Img fluid={service.reportingBackgroundImageMobile.fixed} className="w-auto" /> 
        </div>  
        ) : ( <></> )}
      </div>
      
    ) : ( <></> )}  
    

    {/* Main Body Section */}

    <section className="relative z-10 mb-16 overflow-hidden lg:mb-20">
      <div className="container">
        <div className="flex flex-wrap lg:-mx-12 xl:items-center">
          <div className="w-full mb-5 lg:w-1/2 xl:w-5/12 lg:px-12 lg:mb-0">
          {service.brandLogo && (
            <Img fluid={service.brandLogo.fixed} className="w-48 mb-3" />
          )}
            <div className="w-full content content--reset lg:pr-12 xl:pr-0" dangerouslySetInnerHTML={{ __html: service.content }}></div>
          </div>

          

          {service.slug === "ald-advanced-location-dynamics" ? (
            <div className="w-full ml-auto lg:w-1/2 lg:px-12 sticky top-0">
              <AldVideo />
            </div>
          ) : (
            service.contentImage && (
              <div className="w-full ml-auto lg:w-1/2 lg:px-12">
                <Img
                  backgroundColor={`#3B5CC4`}
                  fluid={service.contentImage.fluid}
                />
              </div>
            )
          )}


        </div>
      </div>
    </section>

    {/* Additional Section */}

    { service.treeChildren.length ? (
      <section className="container mb-12 md:mb-16 xl:mb-20">
        <div className="flex flex-wrap items-center justify-center">
          <span className="block pb-0 my-2 mb-0 text-center h4 text-primary md:mr-6">Additional { service.title } services:</span>
          {service.slug === "ecommerce-agency" ? (

          <div className="flex flex-wrap justify-center">
            {service.treeChildren.map(({ slug, title,  }, index) => {
              return(
                <Link className="block mx-2 mt-2 btn btn--outline btn--outline-primary" key={index} to={`/services/web-design/${service.slug}/${slug}`}>{ title }</Link>
              )
            })}
          </div>
          ) : (
            <div className="flex flex-wrap justify-center">
              {service.treeChildren.map(({ slug, title, ppcOnlyPage }, index) => {
                return(
                  <>
                  {!ppcOnlyPage && (
                    <Link className="block mx-2 mt-2 btn btn--outline btn--outline-primary" key={index} to={`/services/${service.slug}/${slug}`}>{ title }</Link>
                  )}
                  </>
                )
              })}
            </div>
          )}

          <span className="pb-0 my-2 mt-6 mb-0 text-lg text-center md:text-xl xl:text-2xl font-display text-secondary-dark md:ml-8">Call us for more information on <span className="text-primary">0115 959 8900</span></span>
        </div>
      </section>
    ) : (<></>)}

    { service.teamImages.length ? (
    <section className="my-12 overflow-hidden lg:mb-24">
        <div className="container">
          <div className="flex flex-wrap items-center md:-mx-1">
            {service.teamImages.map(({ fluid }, index) => {
              return(
                <div key={index} className="w-full mb-8 md:w-1/3 md:px-1">
                  <Img
                    className="w-full h-auto"
                    backgroundColor={`#3B5CC4`}
                    fluid={fluid}
                  />
                </div>
              )
            })}
          </div>
        </div>
    </section>
    ) : (
      <></>
    )}


    { service.usps.length ? (
    <UspBand
      usps={service.usps}
    />
    ) : (
      <></>
    )}

    {service.stats && service.stats.length > 1 ? (
      <section className="z-10 pb-16 lg:pb-20 overflow-hidden border-grey"> 
        <div className="container">
          <div className="w-full mx-auto md:w-10/12">
            <h2 className="text-center">Advanced Location Dynamics - Benefits</h2>
            <div className="flex flex-wrap md:-mx-6">
              {service.stats.map(({ heading, text }, index) => {
                return(
                  <div className="w-full mb-6 text-center md:w-1/3 md:mb-0 md:px-6" key={index}>
                    <span className="flex items-center justify-center w-40 h-40 mx-auto mb-6 text-5xl text-white rounded-full font-display bg-secondary-dark">{ heading }</span>
                    <span className="block text-xl xl:text-2xl lg:px-6 xl:px-8 2xl:px-10" dangerouslySetInnerHTML={{ __html: text }}></span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
    ) : (
      <></>
    )}

    
    {/* Extra Copy */}

    { service.extraContentCopy ? (
      <section className="relative z-10 pt-16 pb-16 overflow-hidden md:pt-20 lg:pb-20 bg-grey-light">
        <div className="container">
          <div className="w-full md:w-11/12">
            <div className="flex flex-wrap">
              <div className="w-full mb-5 md:w-8/12 lg:mb-0">
                <div className="w-full content content--reset lg:pr-12 xl:pr-0" dangerouslySetInnerHTML={{ __html: service.extraContentCopy }}></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    ) : (
      <></>
    )}
    

    { service.uspsBlock.length ? (
      <section className="py-12 overflow-hidden text-white bg-secondary-dark md:py-20 xl:py-24">
        <div className="container">
          <div className="w-11/12 mx-auto">
            { service.uspsBlockHeading ? (
              <h2 className="mb-12 text-center text-secondary-light md:mb-20 xl:mb-24">{ service.uspsBlockHeading }</h2>
            ) : (
              <></>
            )}

            <div className="flex flex-wrap md:-mx-8">
              {service.uspsBlock.map(({icon, heading, text}, i) => {
                return (
                  <div className="w-full mb-16 md:w-1/2 md:mb-24 md:px-8" key={i}>
                    <div className="relative px-8 border-l border-opacity-25 border-secondary-light md:px-12">
                    <div className="absolute top-0 left-0 w-px h-4 -ml-px bg-primary"></div>
                      { icon && (
                        <div className="flex items-center justify-center w-8 h-8 mb-4 rounded-full md:w-10 md:h-10 bg-primary text-secondary-dark">
                          <FontAwesomeIcon className="inline-block w-3 h-4 text-sm md:text-base" icon={ icon } size="sm" />
                        </div>
                      )}
                      { heading && (
                        <h4 className="text-primary">{ heading }</h4>
                      )}
                      { text && (
                        <div className="content content--primary-list content--nofirst content--primary-links" dangerouslySetInnerHTML={{ __html: text }}></div>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
    ) : (
      <></>
    )}


    { service.miniCaseStudy.length ? (
    <section className="pt-12 overflow-hidden bg-black md:pt-20 xl:pt-24">
      <div className="container">
        {service.miniCaseStudy.map(({metaText, heading, image}, i) => {
          return (
            <div className="flex flex-wrap lg:-mx-8" key={i}>
              <div className="w-full mb-12 lg:w-5/12 lg:px-8 lg:mb-0">
                <span className="block mb-3 text-lg text-grey-dark">{ metaText }</span>
                
                <span className="block mb-6 text-2xl leading-snug md:text-3xl xl:text-4xl font-display lg:mb-16 xl:mb-20 text-gold" dangerouslySetInnerHTML={{ __html: heading }}></span>
              </div>
              <div className="self-end w-full mt-auto lg:w-7/12 lg:px-8">
                <Img fluid={ image.fluid } className="w-10/12 max-w-full mx-auto md:w-full md:max-w-2xl lg:max-w-full"/>
              </div>
            </div>
          )
        })}
      </div>
    </section>
    ) : (<></> )}

    { service.whatSIncludedList.length ? (
      <section className="py-12 overflow-hidden border-b md:py-20 xl:py-24 border-grey-light">
        <div className="container">
          <div className="w-full mx-auto md:w-11/12">
            { service.whatSIncludedTitle && (
              <h2 className="">{ service.whatSIncludedTitle }</h2>
            )}
            { service.whatSIncludedBlurb && (
              <div className="w-9/12 mb-12 xl:w-1/2 md:mb-16 xl:mb-20" dangerouslySetInnerHTML={{ __html: service.whatSIncludedBlurb }}>
              </div>
            )}

            <ul className="flex flex-wrap list-inside list list--fancy md:-mx-8 xl:-mx-12">
              {service.whatSIncludedList.map(({text}, i) => {
                return (
                  <li className="flex flex-wrap w-full mb-8 md:w-1/2 xl:w-1/3 md:px-8 xl:px-12" key={i}>
                    <span className={`mr-3 inline-block text-primary w-auto`}><Triangle size={`small`} /></span>
                    <span className="flex-1 inline-block" dangerouslySetInnerHTML={{ __html:text }}></span>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </section>
    ) : (
      <></>
    )}

    { service.additionalContentHeading ? (
      <section className="py-12 overflow-hidden border-b md:py-20 xl:py-24 border-grey-light">
        <div className="container">
          <div className="w-full mx-auto md:w-11/12">
            <div className="flex flex-wrap mb-8 md:mb-16 xl:mb-20 md:-mx-6 lg:-mx-12">
              <div className="w-full mb-8 md:w-1/2 md:px-6 lg:px-12 md:mb-0">
                {service.additionalContentImage && (
                  <div className="w-full">
                    <Img
                      backgroundColor={`#3B5CC4`}
                      fluid={service.additionalContentImage.fluid}
                    />
                  </div>
                )}
                {service.additionalContentImageTwo && (
                  <div className="w-full mt-6 hidden md:block">
                    <Img
                      backgroundColor={`#3B5CC4`}
                      fluid={service.additionalContentImageTwo.fluid}
                    />
                  </div>
                )}
              </div>
              <div className="w-full md:w-1/2 md:px-6 lg:px-12">
                <div className="mb-12">
                  <h2>{ service.additionalContentHeading }</h2>
                </div>
                
                <div className="content content--primary-list" dangerouslySetInnerHTML={{ __html: service.additionalContentText }}>
                </div>
              </div>
            </div>

            <p className="pb-0 mb-0 text-center align-middle text-secondary-dark font-display h3">Get in touch today on <a href="tel:01159598900" className="inline-block break-all transition duration-500 ease-in-out text-primary hover:text-primary-dark focus:text-primary-dark contactlink">0115 959 8900</a> or <span className="ml-2 align-middle"><Link to="/contact/" className="text-base btn btn--primary">Contact Us</Link></span></p>
          </div>
        </div>
      </section>
    ) : (
      <></>
    )}

    { service.ourServices.length ? (
        
    <div className="container">

      <div className="relative z-10 mb-10 mr-4 bg-transparent md:mr-8 md:mb-10 lg:mb-12 xl:mb-12">
        <div className="pt-16 pb-10  md:pb-0 md:pt-20 lg:pt-24">
          <span class="block h2 text-center mb-12">Your strategy can include the following</span>
          <div className="flex flex-wrap md:-mx-8">
            {service.ourServices.map(({ title, model, slug, treeParent, heroExcerpt, icon }, index) => {
              // Generate the top level slug from the models apiKey
              let slugged = slugify(model.apiKey + 's',{
                lower: true,
              }).replace(/[_]/g, '-');
              let trimmedHeroExcerpt = heroExcerpt.replace(/^(.{120}[^\s]*).*/, "$1...");

              return (
                <div key={index} className="flex flex-wrap w-full mb-8 md:w-1/2 lg:w-1/4 md:mb-16 md:px-8 lg:mb-20">
                  { icon && (
                    // @TODO convert this to SVG component?
                    <div className="w-full">
                      <svg width="35" height="35" className="block mb-8 lg:mb-10">
                        <image href={icon.url} src={icon.url} width="35" height="35"/>
                      </svg>
                    </div>
                  )}

                  <Link to={treeParent ? `/${slugged}/${treeParent.slug}/${slug}` : `/${slugged}/${slug}`} className="block w-full mb-4 h3">{ title }</Link>

                  { heroExcerpt && (
                    <div className="w-full mb-6 text-lg" dangerouslySetInnerHTML={{ __html: trimmedHeroExcerpt }}></div>
                  )}

                  <Link
                    // if it has a parent, pipe it into the link, if not leave it out 
                    to={treeParent ? `/${slugged}/${treeParent.slug}/${slug}` : `/${slugged}/${slug}`}
                    className="block w-full mt-auto text-lg link text-secondary font-display"
                  >Read more <span className={`ml-2 inline-block text-primary border-gray-lighter border-1`}><Triangle size={`small`} /></span></Link>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>

    ) : (
      <></>
    )}
   

    { service.cards.length ? (
      <section className="py-12 overflow-hidden border-b md:py-20 xl:py-24 border-grey-light">
        <div className="container">
          <div className="w-full mx-auto md:w-11/12">
            <div className="flex flex-wrap">
              <div className="w-full">
                <div className="mb-12">
                  { service.cardsBlockTitle && (
                    <h2>{ service.cardsBlockTitle }</h2>
                  )}
                  { service.cardsBlockBlurb && (
                    <div className="w-full md:w-9/12 xl:w-1/2" dangerouslySetInnerHTML={{ __html: service.cardsBlockBlurb }}>
                    </div>
                  )}
                </div>
                
                <div className="flex flex-wrap lg:-mx-6 xl:-mx-12">
                  {service.cards.map(({heading, text}, i) => {
                    var shapeColor = 'primary'
                    var shapeIndex = 1
                    if (i === 0) {
                      shapeColor = 'primary'
                      shapeIndex = 1
                    } else if (i === 1) {
                      shapeColor = 'secondary'
                      shapeIndex = 4
                    } else {
                      shapeColor = 'seconcary-light'
                      shapeIndex = 6
                    }
                    return (
                      <div className="w-full mb-8 lg:w-1/3 lg:px-6 xl:px-12 lg:mb-0" key={i}>
                        <div className="h-full p-8 bg-white rounded shadow-xl md:p-12">
                          <div className="relative w-10 mb-4">
                            <Shape 
                              static={true}
                              index={shapeIndex}
                              color={shapeColor}
                            />
                            <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center font-bold text-white">{ i + 1 }.</div>
                          </div>
                          <h4>{ heading }</h4>
                          <p>{ text }</p>
                        </div>
                      </div>
                    )
                  })}
                </div> 
              </div>
            </div>
          </div>
        </div>
      </section>
    ) : (
      <></>
    )}

    { service.showQuoteBuilderForm && (
      <QuoteBuilder />
    )}


    { service.approachItem.length ? (
      <section className="py-12 overflow-hidden border-b md:py-20 xl:py-24 border-grey-light">
        <div className="container">
          { service.ourApproachHeading && (
            <h2 className="mb-12 text-center md:mb-20">{ service.ourApproachHeading }</h2>
          )}
          <div className="flex flex-wrap justify-center">
            <div className="w-full max-w-2xl">
              {service.approachItem.map(({heading, text}, i) => {
                return (
                  <div className="relative w-full pb-8 pl-12 border-l md:pb-12 border-grey" key={i}>
                    <div className="absolute top-0 left-0 flex items-center justify-center w-8 h-8 -ml-4 font-bold text-white rounded-full bg-primary">{ i + 1 }</div>
                    <span className="block mb-2 text-lg font-bold md:text-xl">{ heading }</span>
                    <div className="content content--nofirst content--primary-list" dangerouslySetInnerHTML={{ __html: text }}></div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
    ) : (
      <></>
    )}

    { service.showAuditForm && (
      <FormAudit />
    )}

    { service.showRankingReportForm && (
      <FormRankingReport />
    )}
    
    { service.featuredCaseStudy && (
      <FeaturedCaseStudy
        title={`Related Project <strong>&middot;</strong> ${service.featuredCaseStudy.title}`}
        slug={service.featuredCaseStudy.slug}
        blurb={service.featuredCaseStudy.teaserBlurb}
        image={service.featuredCaseStudy.teaserIpadImage}
      />
    )}

    { service.showRankingReportForm ? (
      <></>
    ) : (
      <div id="form">
        <FormCta />
      </div>
    )}

  </>
  )
}

export default DigitalServicePage

export const query = graphql`

  query   ($slug: String!) {
    shared: datoCmsShared {
      awardLogo {
        fluid(
          maxWidth: 400
          imgixParams: {h: "814", w: "300"}) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      },
      agencyAwardLogo {
        fluid(
          maxWidth: 400
          imgixParams: {h: "814", w: "300"}) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      },
      webExcellenceAwardLogo {
        fluid(
          maxWidth: 400
          imgixParams: {h: "514", w: "200"}) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      googlePartner {
        fluid(
          maxWidth: 400
          imgixParams: {h: "814", w: "300"}) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      },
    },
    service: datoCmsService(slug: { eq: $slug }) {
      slug
      ppcOnlyPage
      showAuditForm
      showRankingReportForm
      showQuoteBuilderForm
      stats {
        heading
        text
      }
      ourServices {
        ... on DatoCmsService {
          id
          slug
          title
          heroExcerpt
          icon {
            url
          }
          treeParent {
            title
            slug
          }
          model {
            id
            apiKey
            name
          }
        }
        ... on DatoCmsCreativeService {
          id
          slug
          title
          heroExcerpt
          icon {
            url
          }
          treeParent {
            title
            slug
          }
          model {
            id
            apiKey
            name
          }
        }
      }
      treeChildren {
        title
        slug
        ppcOnlyPage
      }
      treeParent {
        slug
      }
      metaTags {
        title
        description
        twitterCard
        image {
          url
        }
      }
      brandLogo {
        fixed(width: 500, height: 200) {
          ...GatsbyDatoCmsFixed
        }
      }
      usps {
        heading
        blurb
      }
      teamImages {
        fluid(
          maxWidth: 900
          imgixParams: {h: "900", w: "900", fit: "crop", crop: "center"}) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      uspsBlockHeading
      uspsBlock {
        heading
        icon
        text
      }
      reportingText 
      reportingBackgroundImage {
        fixed(width: 900, height: 900) {
          ...GatsbyDatoCmsFixed
        }
      }
      reportingBackgroundImageMobile {
        fixed(width: 1024, height: 800) {
          ...GatsbyDatoCmsFixed
        }
      }
      extraContentCopy
      whatSIncludedTitle
      whatSIncludedBlurb
      whatSIncludedList {
        text
      }

      cardsBlockTitle
      cardsBlockBlurb
      cards {
        heading
        text
      }

      miniCaseStudy {
        metaText
        heading
        image {
          fluid(
            maxWidth: 1200
            imgixParams: {h: "600", w: "1200", fit: "crop", crop: "center"}) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }

      ourApproachHeading
      approachItem {
        heading
        text
      }

      additionalContentHeading
      additionalContentText
      additionalContentImage {
        fluid(
          maxWidth: 720
          imgixParams: {h: "720", w: "720", fit: "crop", crop: "faces, edges"}) {
          ...GatsbyDatoCmsFluid
        }
      }
      additionalContentImageTwo {
        fluid(
          maxWidth: 720
          imgixParams: {h: "720", w: "720", fit: "crop", crop: "faces, edges"}) {
          ...GatsbyDatoCmsFluid
        }
      }
      title
      heroHeading
      heroExcerpt
      content
      contentImage {
        fluid(
          maxWidth: 720
          imgixParams: {h: "720", w: "720", fit: "crop", crop: "faces, edges"}) {
          ...GatsbyDatoCmsFluid
        }
      }
      featuredCaseStudy {
        title
        teaserBlurb
        teaserIpadImage {
          fluid(
            maxWidth: 900
            imgixParams: {h: "500", w: "900", fit: "crop", crop: "center"}) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
        slug
      }
    }
  }
`