import React from "react"
import SimpleReactValidator from "simple-react-validator"
import { navigateTo } from "gatsby"

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      company: "",
      website: "",
      enquiry: "",
      service: [],
      marketingSpend: "",
      budget: "",
      showWebDesign: false,
      showSeo: false,
      showBranding: false,
      showPpc: false,
      haveWebsite: true,
      submitting: false,
    };
  }

  componentWillMount() {
    this.validator = new SimpleReactValidator();
  }

  handleSubmit = e => {
    if (this.validator.allValid()) {
      const form = e.target;
      this.setState({
        submitting: true
      })

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state
        })
      })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch(error => alert(error));
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
    e.preventDefault();
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });
  
  webDesignHandleChange = e => this.setState({ [e.target.name]: e.target.value, showWebDesign: !this.state.showWebDesign });
  seoHandleChange = e => this.setState({ [e.target.name]: e.target.value, showSeo: !this.state.showSeo });
  brandingHandleChange = e => this.setState({ [e.target.name]: e.target.value, showBranding: !this.state.showBranding });
  ppcHandleChange = e => this.setState({ [e.target.name]: e.target.value, showPpc: !this.state.showPpc });
  noWebsite = e => this.setState({ haveWebsite: false }, e.preventDefault());
  yesWebsite = e => this.setState({ haveWebsite: true }, e.preventDefault());

  render() {
    const { name, email, phone, company, website, service, enquiry } = this.state;
    return (
      <section className="bg-secondary text-white py-12 md:py-16 xl:py-24 relative z-10" id="quote">
        <div className="container">
          <div className="flex flex-wrap md:-mx-8">
            <div className="w-full md:w-1/3 lg:w-1/4 md:px-8 mb-8 md:mb-0">
              <h2 className="h1">Want a quote?</h2>

              <p className="mb-6">Receive an estimated price for our services based on your requirements and take the first step to improving your performance online.</p>
              
              <p>You can get a quote from us by taking a few seconds to fill in your details and we will get back to you within a few working hours.</p>
            </div>

            <div className="w-full md:w-7/12 lg:w-1/2 md:px-8 md:mx-auto">
              <form
                name="Quote Builder"
                onSubmit={this.handleSubmit}
                className="block w-full overflow-hidden contactform"
                method="post"
                action="/thank-you"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" className="hidden" name="form-name" value="contact-form" />
                <p hidden className="hidden"><label>Don’t fill this out:{" "}<input name="bot-field" /></label></p>
                
                <span className="text-white font-sans pt-2 text-lg pb-3 block">I am looking for:</span>
                <div className="flex flex-wrap -mx-4 mb-5 md:mb-8">
                  <div className="w-1/2 xl:w-1/4 relative px-4 mb-3 xl:mb-0 flex flex-col">
                    <label htmlFor="webDesign" className="block mb-4 custom-checkbox text-center px-5 py-8 h-full">
                      <input type="checkbox" id="webDesign" name={service} value="Web Design" onChange={ this.webDesignHandleChange } />
                      <span className="checkmark">
                      </span>
                      <span className="block relative z-10">
                        <img src="/web.svg" alt="Web Design Icon" className="w-8 h-8 block mb-4 pb-0 relative z-10 mx-auto" /> 
                        <span className="text-secondary font-display text-lg block leading-tight">Web Design</span>
                      </span>
                    </label>
                  </div>

                  <div className="w-1/2 xl:w-1/4 relative px-4 mb-3 xl:mb-0 flex flex-col">
                    <label htmlFor="seo" className="block mb-4 custom-checkbox text-center px-5 py-8 h-full">
                      <input type="checkbox" id="seo" name={service} value="SEO" onChange={ this.seoHandleChange} />
                      <span className="checkmark">
                      </span>
                      <span className="block relative z-10">
                        <img src="/seo.svg" alt="SEO Icon" className="w-8 h-8 block mb-4 pb-0 relative z-10 mx-auto" /> 
                        <span className="text-secondary font-display text-lg block">SEO</span>
                      </span>
                    </label>
                  </div>

                  <div className="w-1/2 xl:w-1/4 relative px-4 mb-3 xl:mb-0 flex flex-col">
                    <label htmlFor="branding" className="block mb-4 custom-checkbox text-center px-5 py-8 h-full">
                      <input type="checkbox" id="branding" name={service} value="Branding" onChange={ this.brandingHandleChange } />
                      <span className="checkmark">
                      </span>
                      <span className="block relative z-10">
                        <img src="/graphics.svg" alt="Graphics Icon" className="w-8 h-8 block mb-4 pb-0 relative z-10 mx-auto" /> 
                        <span className="text-secondary font-display text-lg block">Branding</span>
                      </span>
                    </label>
                  </div>

                  <div className="w-1/2 xl:w-1/4 relative px-4 mb-3 xl:mb-0 flex flex-col">
                    <label htmlFor="ppc" className="block mb-4 custom-checkbox text-center px-5 py-8 h-full">
                      <input type="checkbox" id="ppc" name={service} value="PPC" onChange={ this.ppcHandleChange} />
                      <span className="checkmark">
                      </span>
                      <span className="block relative z-10">
                        <img src="/ppc.svg" alt="PPC Icon" className="w-8 h-8 block mb-4 pb-0 relative z-10 mx-auto" /> 
                        <span className="text-secondary font-display text-lg block">PPC</span>
                      </span>
                    </label>
                  </div>
                </div>

                <div className="mb-5 md:mb-8">
                  <div className="flex flex-wrap md:-mx-4">
                    <div className="w-full md:w-1/2 md:px-4">
                      <label htmlFor="name" className="block mb-4">
                        <span className="text-white font-sans pt-2 text-lg pb-3 block">My name is: *</span>
                        <input type="text" name="name" id="name" className="block w-full bg-secondary-dark bg-opacity-25 text-white p-3 md:p-4 border-4 border-transparent focus:border-secondary-light outline-none appearance-none text-lg md:text-xl" placeholder="John Doe" value={name} onChange={this.handleChange} />

                        <span className="block mt-2 text-secondary-light text-sm">
                          {this.validator.message('name', name, 'required')}
                        </span>
                      </label>
                    </div>

                    <div className="w-full md:w-1/2 md:px-4">
                      <label htmlFor="phone" className="block mb-4">
                        <span className="text-white font-sans pt-2 text-lg pb-3 block">My phone number is: *</span>
                        <input type="text" name="phone" id="phone" className="block w-full bg-secondary-dark bg-opacity-25 text-white p-3 md:p-4 border-4 border-transparent focus:border-secondary-light outline-none appearance-none text-lg md:text-xl" placeholder="0800 123 4567" value={phone} onChange={this.handleChange} />

                        <span className="block mt-2 text-secondary-light text-sm">
                          {this.validator.message('phone', phone, 'required|phone')}
                        </span>
                      </label>
                    </div>
                  </div>

                  <div className="flex flex-wrap md:-mx-4">
                    <div className="w-full md:w-1/2 md:px-4">
                      <label htmlFor="email" className="block mb-4">
                        <span className="text-white font-sans pt-2 text-lg pb-3 block">My email address is: *</span>
                        <input type="text" name="email" id="email" className="block w-full bg-secondary-dark bg-opacity-25 text-white p-3 md:p-4 border-4 border-transparent focus:border-secondary-light outline-none appearance-none text-lg md:text-xl" placeholder="info@mycompany.co.uk" value={email} onChange={this.handleChange} />
                          
                        <span className="block mt-2 text-secondary-light text-sm">
                          {this.validator.message('email', email, 'required|email')}
                        </span>
                      </label>
                    </div>

                    <div className="w-full md:w-1/2 md:px-4">
                      <label htmlFor="company" className="block mb-4">
                        <span className="text-white font-sans pt-2 text-lg pb-3 block">My company name is: *</span>
                        <input type="text" name="company" id="company" className="block w-full bg-secondary-dark bg-opacity-25 text-white p-3 md:p-4 border-4 border-transparent focus:border-secondary-light outline-none appearance-none text-lg md:text-xl" placeholder="Company Ltd" value={company} onChange={this.handleChange} />
                          
                        <span className="block mt-2 text-secondary-light text-sm">
                          {this.validator.message('company', company, 'required')}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className={ this.state.showWebDesign || this.state.showSeo || this.state.showBranding || this.state.showPpc ? `block` : `hidden`}>
                  <label htmlFor="website" className="block mb-4">
                    <span className="text-white font-sans pt-2 text-lg pb-3 block">
                      I <button className={ this.state.haveWebsite ? `bg-white rounded p-px px-2 text-secondary`: `bg-white rounded p-px px-2 text-secondary opacity-50` }  onClick={ this.yesWebsite }>have</button> / <button className={ this.state.haveWebsite ? `bg-white rounded p-px px-2 text-secondary opacity-50`: `bg-white rounded p-px px-2 text-secondary` } onClick={ this.noWebsite }>do not have</button> an existing website { this.state.haveWebsite ? (
                      <>, the address is:</>): (<></>)}
                    </span>
                    <input type="text" name="website" id="website" className={ this.state.haveWebsite ? `block w-full bg-secondary-dark bg-opacity-25 text-white p-3 md:p-4 border-4 border-transparent focus:border-secondary-light outline-none appearance-none text-lg md:text-xl` : `hidden w-full bg-secondary-dark bg-opacity-25 text-white p-3 md:p-4 border-4 border-transparent focus:border-secondary-light outline-none appearance-none text-lg md:text-xl`} placeholder="https://example.co.uk" value={website} onChange={this.handleChange} />
                  </label>
                  
                  {/* <label htmlFor="marketingSpend" className={ this.state.showSeo || this.state.showPpc ? `block mb-4` : `hidden mb-4`}>
                    <span className="text-white font-sans pt-2 text-lg pb-3 block">My current monthly marketing spend is:</span>
                    <input type="text" name="marketingSpend" id="marketingSpend" className="block w-full bg-secondary-dark bg-opacity-25 text-white p-3 md:p-4 border-4 border-transparent focus:border-secondary-light outline-none appearance-none text-lg md:text-xl" placeholder="£000" value={marketingSpend} onChange={this.handleChange} />

                    <input type="radio" id="male" name="gender" value="male">
                    <label for="male">Male</label><br>
                    <input type="radio" id="female" name="gender" value="female">
                    <label for="female">Female</label><br>
                    <input type="radio" id="other" name="gender" value="other">
                    <label for="other">Other</label>
                  </label> */}

                  <fieldset className="mb-4">
                    <legend className="text-white font-sans pt-2 text-lg pb-3 block">My current monthly marketing spend is:</legend>

                    <div className="flex flex-wrap -mx-3">
                      <p className="mb-1 px-3">
                        <label className="text-sm">
                          <input type="radio" name="marketingSpend" onChange={this.handleChange} value="£250 - £1,000" /> £250 - £1,000
                        </label>
                      </p>
                      <p className="mb-1 px-3">
                        <label className="text-sm">
                          <input type="radio" name="marketingSpend" onChange={this.handleChange} value="£1,000 - £2,000" /> £1,000 - £2,000
                        </label>
                      </p>
                      <p className="mb-1 px-3">
                        <label className="text-sm">
                          <input type="radio" name="marketingSpend" onChange={this.handleChange} value="£2,000 - £5,000" /> £2,000 - £5,000
                        </label>
                      </p>
                      <p className="mb-1 px-3">
                        <label className="text-sm">
                          <input type="radio" name="marketingSpend" onChange={this.handleChange} value="£5,000 +" /> £5,000 +
                        </label>
                      </p>
                    </div>
                  </fieldset>

                  <fieldset className="mb-4">
                    <legend className="text-white font-sans pt-2 text-lg pb-3 block">My website/branding budget is:</legend>

                    <div className="flex flex-wrap -mx-3">
                      <p className="mb-1 px-3">
                        <label className="text-sm">
                          <input type="radio" name="budget" onChange={this.handleChange} value="£1,000 - £2,500" /> £1,000 - £2,500
                        </label>
                      </p>
                      <p className="mb-1 px-3">
                        <label className="text-sm">
                          <input type="radio" name="budget" onChange={this.handleChange} value="£2,500 - £5,000" /> £2,500 - £5,000
                        </label>
                      </p>
                      <p className="mb-1 px-3">
                        <label className="text-sm">
                          <input type="radio" name="budget" onChange={this.handleChange} value="£5,000 - £10,000" /> £5,000 - £10,000
                        </label>
                      </p>
                      <p className="mb-1 px-3">
                        <label className="text-sm">
                          <input type="radio" name="budget" onChange={this.handleChange} value="£10,000 - £15,000" /> £10,000 - £15,000
                        </label>
                      </p>
                      <p className="mb-1 px-3">
                        <label className="text-sm">
                          <input type="radio" name="budget" onChange={this.handleChange} value="£15,000 +" /> £15,000 +
                        </label>
                      </p>
                    </div>
                  </fieldset>

                  {/* <label htmlFor="budget" className="block mb-8">
                    <span className="text-white font-sans pt-2 text-lg pb-3 block">My budget is:</span>
                    <input type="text" name="budget" id="budget" className="block w-full bg-secondary-dark bg-opacity-25 text-white p-3 md:p-4 border-4 border-transparent focus:border-secondary-light outline-none appearance-none text-lg md:text-xl" placeholder="£000" value={budget} onChange={this.handleChange} />
                  </label> */}

                  <label htmlFor="enquiry" className="block mb-6">
                    <span className="text-white font-sans pt-2 text-lg pb-3 block">I am looking for (give us as much detail as possible):</span>
                    <textarea id="enquiry" name="enquiry" className="block w-full bg-secondary-dark bg-opacity-25 text-white p-3 md:p-4 border-4 border-transparent focus:border-secondary-light outline-none appearance-none text-lg md:text-xl" rows="6" value={enquiry} onChange={this.handleChange} placeholder="A new marketing strategy..."></textarea>
                  
                  </label>
                </div>


                <div className="flex justify-center">
                  <button type="submit" disabled={this.state.submitting} className="btn btn--primary">{ this.state.submitting ? 'Sending...' : 'Submit Quote Enquiry' }</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Form
